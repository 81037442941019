@media screen and (min-width: 768px) {
  /* align songpost right */
  #request {
    text-align: right !important;
  }
  .browser-postview {
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }

  .browser-postview::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
}
